import React from "react"
import { Link } from "gatsby"
import { Flex, Box } from "rebass"
import Figure from "../components/Figure"
import { H1, H3, P, Ul } from "../components/VerticalRhythm"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Section from "../components/Section"
import RespGrid from "../components/RespGrid"
import breakpoints from "../theme/breakpoints"

import PictureSet from "../components/PictureSet"

const ImprintPage = () => (
  <Layout>
    <SEO title="Impressum" />
    <Figure id={"master"} caption="">
      {/* <PictureSet imgObj={} /> */}
    </Figure>
    <Section>
      <Link to="/">← Tooth Robber</Link>
      <H1>Impressum</H1>
      <H3>Verantwortlich</H3>
      <P>
        Karl Anders
        <br />
        Stüttgerhofweg 4f
        <br />
        50858 Köln
        <br />
        Germany
        <br />
        info@zahnraeuber.de
      </P>
      <div style={{ height: "400px" }}></div>
    </Section>
  </Layout>
)

export default ImprintPage
